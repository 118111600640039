import { getMainRoutes } from './main-routes'

export const getRoutes = async () => {
  return [
    {
      type: 'div',
      attrs: [
        {
          name: 'class',
          value: 'ph-app',
        },
      ],
      routes: [
        // navigation
        {
          type: 'nav',
          attrs: [
            {
              name: 'class',
              value: 'ph-header',
            },
          ],
          routes: [
            {
              type: 'application',
              name: '@rmp/navigation',
            },
          ],
        },

        // main
        {
          type: 'main',
          attrs: [
            {
              name: 'class',
              value: 'ph-main',
            },
          ],
          routes: [
            {
              type: 'application',
              name: '@rmp/app-global',
              props: {
                enablePages: false,
              },
            },
            //global-partner-chatbot
            {
              type: 'application',
              name: '@pha/ph-global-chatbot',
              countries: ['uk'],
            },
            ...getMainRoutes(),
            {
              type: 'route',
              default: true,
              routes: [
                {
                  type: 'application',
                  name: '@rmp/app-global-pages',
                  props: {
                    enableGlobals: false,
                    useNotFoundPage: true,
                  },
                },
              ],
            },
          ],
        },

        // footer
        {
          type: 'footer',
          attrs: [
            {
              name: 'class',
              value: 'ph-footer',
            },
          ],
          routes: [
            {
              type: 'application',
              name: '@pha/footer',
            },
          ],
        },
      ],
    },
  ]
}
