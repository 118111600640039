export const getRedirects = async () => [
  {
    from: '/',
    to: '/home',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz', 'ca'],
  },
  {
    from: '/settings/item-availability',
    to: '/menu/item-availability',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/settings/menu',
    to: '/menu/edit',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/live-reporting',
    to: '/home',
    countries: ['uk', 'es', 'it', 'ie', 'au'],
  },
  {
    from: '/performance',
    to: '/analytics/performance-score',
    countries: ['uk', 'es', 'it', 'ie', 'au'],
  },
  {
    from: '/marketing',
    to: '/marketing/offers',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/help',
    to: '/help/support-requests',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/menu',
    to: '/menu/item-availability',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/orders-and-invoices',
    to: '/orders-and-invoices/order-history',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/settings',
    to: '/settings/restaurant-details/edit-business-details',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
  {
    from: '/help/cookies-policy',
    to: '/cookies-statement',
    countries: ['uk', 'es', 'it', 'ie', 'au', 'nz'],
  },
]
